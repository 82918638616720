<template>
  <div class="content">
    <header>
      <div class="logo"></div>
      <div class="title">
        <span>睿识课</span>
        <span>举安全之盾，防事故之患</span>
      </div>
      <div class="button" @click="turnDownload()">关注</div>
    </header>
    <div class="player" :style="'background-image:url(' + video1.poster + ')'">
      <div class="cover"></div>
      <span @click="playVideo('925')"></span>
    </div>
    <div class="player-info">
      <div class="title">
        <div class="logo"></div>
        <div class="name">睿识课</div>
      </div>
      <div class="desc">
        {{ desc }}
      </div>
    </div>
    <footer>
      <div :style="'background-image:url(' + video2.poster + ')'">
        <div class="cover"></div>
        <span @click="playVideo('707')"></span>
      </div>
      <div :style="'background-image:url(' + video3.poster + ')'">
        <div class="cover"></div>
        <span @click="playVideo('604')"></span>
        <!-- <div class="info">
          <span>还有1000+节的生活安全课程等您来学习。</span>
          <span @click="turnDownload()">去观看</span>
        </div> -->
      </div>
    </footer>
    <div class="cover-bg" v-if="isDisplay">
      <div class="dialog">
        <div class="slogan"></div>
        <div class="btn">
          <div class="btn-button" @click="turnDownload()">立即领取</div>
          <p>睿识课—邀请函</p>
        </div>
      </div>
      <div class="close-dialog" @click="closeDialog()"></div>
    </div>
    <div class="cover-bg" v-if="isLogin">
      <div class="login">
        <form action="">
          <div class="phone">
            <div class="login-icon phone-icon"></div>
            <input type="text" placeholder="请输入手机号码" v-model="dataForm.phone" @input="dataForm.phone=clearNoNum(dataForm.phone,'decimal') "/>
          </div>
          <div class="verified">
            <div class="login-icon code-icon"></div>
            <input type="text" placeholder="请输入验证码" v-model="dataForm.verifyCode" @input="dataForm.verifyCode=clearNoNum(dataForm.verifyCode,'decimal')"/>
            <div :class="'get-verified' + (isDisCaptcha === true ? ' isDisCaptcha': '')" @click.stop="getCaptcha()">{{ isDisCaptcha === true ? captchaTime + ' S' : '获取验证码'}}</div>
          </div>
          <div class="submit-verified" @click.stop="submitDataForm()">继续观看</div>
        </form>
        <div class="close-login" @click="closeLogin()"></div>
      </div>
    </div>
    <div v-if="isPlay" class="player-box">
      <div class="close-player" @click="closePlayer()"></div>
      <video-player
        style="object-fit: fill"
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :options="playerOptions"
        @play="onPlayerPlay($event)"
        @pause="onPlayerPause($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        @canplay="onPlayerCanplay($event)"
        @ended="onPlayerEnded($event)"
      ></video-player>
    </div>
  </div>
</template>

<script>
import { getVerifyCode,VerifyPhone } from "@api/demo/index";
import axios from "axios";
import wx from "weixin-js-sdk";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
// import { getUserInfo } from "@api/demo/index";
import { isMobile } from '@/utils/validate'
export default {
  name: "test",
  data() {
    return {
      isBefore: false,
      isDisplay: false,
      isLogin: false,
      isPlay: false,
      videoPlayer: null,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        // aspectRatio: '750:1466',
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: "", // url地址
          },
        ],
        poster: "", // 初始封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      titlle: "",
      desc: "",
      video1: {
        id: "925",
        title: "冻伤的自救和急救措施",
        poster:
          "https://rskjy.oss-cn-beijing.aliyuncs.com/default/e9c7876c620f432429d50a60031f8f28.png",
        src:
          "http://oss-video.rskjy.cn/sv/53e9f6bf-176843b83f7/53e9f6bf-176843b83f7.mp4",
        desc:
          "在严冬或长时间低温环境下，容易发生冻伤，重度冻伤时，机体组织会严重受损。本节介绍了冻伤的自救和急救措施。",
      },
      video2: {
        id: "707",
        title: "关于那些小孩烫伤后的处理问题",
        poster:
          "https://rskjy.oss-cn-beijing.aliyuncs.com/default/58ebf4fedb9a39a57c6a8595580cfcc1.png",
        src:
          "http://oss-video.rskjy.cn/sv/5dcafa6e-176844c693c/5dcafa6e-176844c693c.mp4",
        desc:
          "主要讲解在小孩子被烫伤后，我们应该如何及时地采取正确的安全措施，避免孩子受到二次伤害。",
      },
      video3: {
        id: "604",
        title: "癫痫发作的症状和需要避免的事项",
        poster:
          "https://rskjy.oss-cn-beijing.aliyuncs.com/default/73d2bc0889b7641ecdea816fe469d315.png",
        src:
          "http://oss-video.rskjy.cn/sv/2fa1db96-176842e3fce/2fa1db96-176842e3fce.mp4",
        desc: "癫痫发作是一种人严重不能控制的躯体的抽搐或痉挛，并伴有不同程度意识的改变。本节介绍了癫痫发作的症状和需要避免的事项。",
      },
      userInfo: null,
      dataForm:{
        phone:'',
        verifyCode:''
      },
      captchaTime:60,
      timeFlag:null,
      isDisCaptcha:false,
      currentTime:0,
      isAlert:false
    };
  },
  watch: {
  },
  created() {
    // this.is_weixin();
    this.init();
  },
  mounted() {
    this.initPlayer();
    this.initWxShare();
  },
  components: {
    videoPlayer,
  },
  methods: {
    // 判断是否微信浏览器
    is_weixin() {
      var ua = navigator.userAgent.toLowerCase(); //判断浏览器的类型
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        // window.location.href =
        //   "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbd2ee760fd93777a&redirect_uri=http://test-demo.rskjy.cn/demo&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect"; //不是就跳转提示页面
      }
    },
    // 初始化
    init() {
      window.alert = function(name){
          var iframe = document.createElement("IFRAME");
          iframe.style.display="none";
          iframe.setAttribute("src", 'data:text/plain,');
          document.documentElement.appendChild(iframe);
          window.frames[0].window.alert(name);
          iframe.parentNode.removeChild(iframe);
      }
      // let openID = this.$cookie.get("openId");
      // let code = this.$cookie.get("code");
      // if (openID) {
      //   let params = { openID: openID };
      //   getUserInfo(params).then(({ data }) => {
      //     if (data && data.success === true) {
      //       this.userInfo = data.data;
      //     } else {
      //       console.log(data.message);
      //     }
      //   });
      // } else {
      //   if (code) {
      //     let params = { code: code };
      //     getOpenId(params).then(({ data }) => {
      //       if (data && data.success === true) {
      //         this.userInfo = data.data;
      //       } else {
      //         console.log(data.message);
      //       }
      //     });
      //   } else {
      //     let code = this.$route.query.code;
      //     if (code != null) {
      //       //微信回调的页面
      //       this.$cookie.set("code", code, 60 * 60);
      //     }else{
      //       window.location.href =
      //   "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbd2ee760fd93777a&redirect_uri=http://test-demo.rskjy.cn/demo&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect";
      //     }
      //   }
      // }
      // let code = this.$cookie.get("code");
      // if (code) {
      //   let params = { code: code };
      //   getUserInfo(params).then(({ data }) => {
      //     console.log(data);
      //     if (data && data.success === true) {
      //       this.userInfo = data.data;
      //     } else {
      //       console.log(data.message);
      //     }
      //   });
      // } else {
      //   let code = this.$route.query.code;
      //   if (code != null) {
      //     //微信回调的页面
      //     this.$cookie.set("code", code, 60 * 60);
      //   } else {
      //     window.location.href =
      //       "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbd2ee760fd93777a&redirect_uri=http://test-demo.rskjy.cn/demo&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect";
      //   }
      // }
    },
    playVideo(id) {
      let info = {};
      switch (id) {
        case "925":
          info = this.video1;
          break;
        case "707":
          info = this.video2;
          break;
        case "604":
          info = this.video3;
          break;
        default:
          info = this.video1;
      }
      this.playerOptions.poster = info.poster;
      this.playerOptions.sources[0].src = info.src;
      // this.desc = info.desc;
      this.isPlay = true;
    },
    initPlayer() {
      const id = this.$route.query.id;
      let info = {};
      switch (id) {
        // case "82":
        //   info = this.video1;
        //   break;
        // case "655":
        //   info = this.video2;
        //   break;
        // case "904":
        //   info = this.video3;
        //   break;
        default:
          info = this.video1;
      }
      this.playerOptions.poster = info.poster;
      this.playerOptions.sources[0].src = info.src;
      this.desc = info.desc;
    },
    // eslint-disable-next-line no-unused-vars
    onPlayerCanplay(player) {
      // console.log('player Canplay!', player)
      //解决自动全屏
      let ua = navigator.userAgent.toLocaleLowerCase();
      let video = document.getElementsByTagName("video")[0];
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        // x5内核
        video.setAttribute("x5-video-player-type", "h5-page"); // 声明启用同层H5播放器，控制网页内部同层播放，可以在视频上方显示html元素。
        video.setAttribute("x5-video-orientation", "landscape|portrait"); // 播放器支持的方向--跟随手机自动旋转，此属性只在声明了x5-video-player-type=”h5”情况下生效
        video.setAttribute("x5-video-player-fullscreen", "true"); // 进入全屏通知
      } else {
        // ios端
        video.setAttribute("webkit-playsinline", "true"); //设置ios在微信中内联播放视频 ios9
        video.setAttribute("playsinline", "true"); //设置ios在微信中内联播放视频 ios10/ios11
      }
    },
    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate($event) {
      this.currentTime = Math.ceil($event.cache_.currentTime)
      if (Math.ceil($event.cache_.currentTime) > 60) {
        this.isLoginUser()
      }
      if (Math.ceil($event.cache_.duration - $event.cache_.currentTime) == 3) {
        if(this.isLogin != true){
          setTimeout(() => {
            if(this.isLogin != true){
              this.isBefore = true;
              this.isDisplay = true;
            }
          }, 200);
        }
      }
    },
    // 播放回调
    // eslint-disable-next-line no-unused-vars
    onPlayerPlay (player) {
      // console.log('player play!', player)
      if(Math.ceil(this.currentTime) > 60){
        this.isLoginUser()
        return false
      }
    },
    // 暂停回调
    onPlayerPause (player) {
      console.log('player pause!', player)
    },
    isLoginUser(){
      let phoneNo = this.$cookie.get("phoneNo");
        if(!phoneNo){
          this.isLogin = true
          this.$refs.videoPlayer.player.pause()
            this.exitFullScreen()
          return false
        }
    },
    // 视频播完回调
    // eslint-disable-next-line no-unused-vars
    onPlayerEnded($event) {
      if (this.isBefore === true) {
        this.isBefore === false;
      } else {
        this.isDisplay = true;
      }
      // console.log($event);
    },
    // 退出全屏，恢复正常大小
    exitFullScreen () {
      if(this.isAlert ==  false){
        this.isAlert = true
        setTimeout(() => {
          this.isAlert = false
        }, 200);
        alert('试看已结束')
      }
    },
    closeDialog() {
      this.isDisplay = false;
    },
    closePlayer() {
      this.isPlay = false;
    },
    // 跳转平台
    turnDownload() {
      let url = "https://bxgxmh.jgmlink.cn/AA8a"; //ios
      this.loadURL(url);
    },
    // 给原生传递数据
    loadURL(url) {
      var iFrame;
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        //安卓终端使用iframe
        iFrame = document.createElement("iframe");
        iFrame.setAttribute("src", "https://bxgxmh.jgmlink.cn/AA8a");
        iFrame.setAttribute("style", "display:none;");
        iFrame.setAttribute("height", "0px");
        iFrame.setAttribute("width", "0px");
        iFrame.setAttribute("frameborder", "0");
        document.body.appendChild(iFrame);
        // 发起请求后这个 iFrame 就没用了，所以把它从 dom 上移除掉
        iFrame.parentNode.removeChild(iFrame);
        iFrame = null;
      } else if (isiOS) {
        //iOS终端直接页面跳转
        window.location.href = url;
        // 如果用户没有安装淘宝APP，则提示用户去安装淘宝
        // setTimeout(() => {
        //   alert("请去应用市场下载App"); // 这里可以自行写一个延时关闭的弹窗，也可以跳转至app下载地址，我这里就偷懒了
        // }, 2000);
      } else {
        window.location.href = url;
      }
    },
    // 获取验证码
    getCaptcha () {
      if(!isMobile(this.dataForm.phone)){
        this.$toast.fail('手机格式错误');
        return false
      }
      if (this.isDisCaptcha === true && this.captchaTime !== 60) {
        return false
      } else {
        let param = {phone:this.dataForm.phone}
        getVerifyCode(param).then(({ data }) => {
          if (data && data.success === true) {
            this.$toast.success('已发送');
          } else {
            this.$toast.fail(data.message)
          }
        })
        this.isDisCaptcha = true
        this.timeFlag = setInterval(() => {
          this.captchaTime--
          if (this.captchaTime <= 0) {
            window.clearInterval(this.timeFlag)
            this.captchaTime = 60
            this.isDisCaptcha = false
          }
        }, 1000)
      }
    },
    submitDataForm(){
      if(!isMobile(this.dataForm.phone)){
        this.$toast.fail('手机格式错误');
        return false
      }
      let params = {...this.dataForm}
      VerifyPhone(params).then(({ data }) => {
        if (data && data.success === true) {
          this.$toast.success('验证成功');
          this.$cookie.set("phoneNo", data.data.phoneNo);
          this.closeLogin()
        } else {
          this.$toast.fail(data.message)
        }
      })
    },
    closeLogin(){
      this.isLogin = false
      this.dataForm.phone = ''
      this.dataForm.code = ''
    },
    initWxShare() {
      let url = 'http://test-oss-api.rskjy.cn/wx/getSign?homeUrl=' + encodeURIComponent(window.location.href.split("#")[0])
      axios.get(url).then(({ data }) => {
        if (data && data.success === true) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.data.appId, // 必填，公众号的唯一标识
            timestamp: data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
            signature: data.data.signature.toLowerCase(), // 必填，签名
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
          });
          wx.ready(function () {
          let shareConfig = {
              imgUrl: "https://rskjy.oss-cn-beijing.aliyuncs.com/demo/uxin.png", // 这里是需要展示的图标
              desc: "免费领取3节新司机成为老司机的武功秘籍", // 这是分享展示的摘要
              title: "优信&睿识课", // 这是分享展示卡片的标题
              link: "http://test-demo.rskjy.cn/demo", // 这里是分享的网址
              success: function (rr) {
                console.log("成功" + JSON.stringify(rr));
              },
              cancel: function (tt) {
                console.log("失败" + JSON.stringify(tt));
              },
            }
            wx.updateAppMessageShareData(shareConfig); // 微信好友 需要开通微信认证 300元/次/年
          });
        } else {
          console.log(data.message);
        }
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  height: 1218px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #000000;
  position: relative;
  /deep/ span {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  /deep/ span:before {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  /deep/ div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  /deep/ div:before {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  /deep/ button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  header {
    height: 100px;
    width: 100%;
    background: linear-gradient(101deg, #28a0e2 0%, #46a9ef 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-around;
    .logo {
      width: 80px;
      height: 80px;
      background: url(../assets/img/logo_big@2x.png) no-repeat center;
      background-size: 80px 80px;
      margin-left: 30px;
      margin-right: 14px;
    }
    .title {
      display: flex;
      flex-direction: column;
      width: 422px;
      margin-right: 14px;
      span {
        font-family: PingFangSC-Regular, PingFang SC;
        &:nth-of-type(1) {
          font-size: 36px;
          font-weight: 500;
          color: #ffffff;
          line-height: 40px;
          margin-bottom: 6px;
        }
        &:nth-of-type(2) {
          font-size: 24px;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
        }
      }
    }
    .button {
      width: 160px;
      height: 62px;
      background: #ffffff;
      border-radius: 31px;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #3ba6ea;
      line-height: 62px;
      text-align: center;
    }
  }
  .player {
    width: 100%;
    height: 421px;
    margin-top: 120px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    position: relative;
    .cover {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #000000;
      opacity: 0.26;
    }
    span {
      width: 130px;
      height: 130px;
      background: url(../assets/img/icon_play@2x.png) no-repeat center;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -65px;
      margin-top: -65px;
    }
  }
  .player-info {
    width: 100%;
    .title {
      height: 100px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      .logo {
        width: 50px;
        height: 50px;
        background: url(../assets/img/logo_big@2x.png) no-repeat center;
        background-size: 50px 50px;
        margin-right: 15px;
      }
      .name {
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 45px;
      }
    }
    .desc {
      padding: 0 29px 0 25px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      line-height: 40px;
    }
  }
  footer {
    width: 100%;
    position: absolute;
    bottom: 40px;
    left: 0;
    display: flex;
    justify-content: space-between;
    > div {
      width: 368px;
      height: 207px;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      span {
        width: 80px;
        height: 80px;
        background: url(../assets/img/icon_play@2x.png) no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -40px;
        margin-top: -40px;
      }
      &:nth-of-type(1) {
        // background: url(../assets/img/player_cover_left.jpg) no-repeat center;
        .cover {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #000000;
          opacity: 0.26;
        }
      }
      &:nth-of-type(2) {
        // background: url(../assets/img/player_cover_right.jpg) no-repeat center;
        .cover {
          width: 100%;
          height: 100%;
          background: linear-gradient(270deg, #5a5a5a 0%, #000000 100%);
          opacity: 0.47;
        }
        .info {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 40px 0 25px 0;
          span {
            &:nth-of-type(1) {
              padding: 0 24px;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 32px;
              margin-bottom: 26px;
            }
            &:nth-of-type(2) {
              width: 110px;
              height: 46px;
              border-radius: 4px;
              border: 1px solid #ffffff; /* no*/
              font-size: 24px;
              text-align: center;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 46px;
            }
          }
        }
      }
    }
  }
  .cover-bg {
    height: 100%;
    width: 100%;
    background: rgba(49, 41, 41, 0.46);
    border-radius: 1px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .dialog {
      width: 473px;
      height: 479px;
      background: url(../assets/img/dialog_bg_@2x.png) no-repeat center;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      padding-top: 34px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      .slogan {
        width: 356px;
        height: 102px;
        background: url(../assets/img/slogan@2x.png) no-repeat center;
        background-size: 100% 100%;
      }
      .btn {
        width: 100%;
        height: 194px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn-button {
          width: 240px;
          height: 58px;
          background: #ffffff;
          border-radius: 29px;
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2e5594;
          line-height: 58px;
          text-align: center;
          -webkit-animation: free_download 0.5s linear alternate infinite;
          animation: free_download 0.5s linear alternate infinite;
        }
        p {
          text-align: center;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #d2e5f8;
          line-height: 22px;
        }
      }
    }
    .close-dialog {
      width: 62px;
      height: 62px;
      background: url(../assets/img/icon_delete@2x.png) no-repeat center;
      background-size: 100% 100%;
      margin-top: 79px;
    }
    .login {
      width: 570px;
      height: 368px;
      background: #ffffff;
      border-radius: 6px;
      position: relative;
      form {
        width: 490px;
        height: 288px;
        display: flex;
        flex-direction: column;
        padding: 40px;
        > div {
          width: 100%;
          margin-top: 24px;
          position: relative;
          &:nth-of-type(1) {
            margin-top: 0;
          }
        }
        input {
          height: 32px;
          font-size: 32px;
          font-weight: 400;
          color: #666666;
          line-height: 32px;
          padding: 24px 24px 24px 58px;
          border-radius: 6px;
          border: 1px solid #40abfe; /* no*/
        }
        input::-webkit-input-placeholder {
          font-weight: 400;
          color: #b9b9b9;
        }

        input:-moz-placeholder {
          font-weight: 400;
          color: #b9b9b9;
        }

        input::-moz-placeholder {
          font-weight: 400;
          color: #b9b9b9;
        }

        input:-ms-input-placeholder {
          font-weight: 400;
          color: #b9b9b9;
        }
        .login-icon {
          width: 38px;
          height: 38px;
          position: absolute;
          left: 10px;
          top: 21px;
        }
        .phone-icon{
          background: url(../assets/img/shoujihaoma@2x.png) no-repeat center;
          background-size: 38px 38px;
        }
        .code-icon {
          background: url(../assets/img/yanzhengma@2x.png) no-repeat center;
          background-size: 38px 38px;
        }
        .phone {
          input {
            width: 406px;
          }
        }
        .verified {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          input {
            width: 216px;
          }
          .get-verified {
            width: 170px;
            height: 80px;
            background: #40abfe;
            border-radius: 6px;
            font-size: 28px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 80px;
            border: 1px solid #40abfe; /* no*/
          }
          .isDisCaptcha{
            background: #b9b9b9;
            border: 1px solid #b9b9b9; /* no*/
          }
        }
        .submit-verified {
          width: 490px;
          height: 80px;
          background: #40abfe;
          border-radius: 6px;
          text-align: center;
          font-size: 32px;
          font-weight: 500;
          color: #ffffff;
          line-height: 80px;
          letter-spacing: 1px;
        }
      }
      .close-login{
        width: 62px;
      height: 62px;
      background: url(../assets/img/icon_delete@2x.png) no-repeat center;
      background-size: 100% 100%;
        position: absolute;
        top: -120px;
        right: 0;
        cursor: pointer;
      }
    }
  }
  .player-box {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.93);
    // background-color: rosybrown;
    .close-player {
      position: absolute;
      right: 30px;
      top: 30px;
      width: 62px;
      height: 62px;
      background: url(../assets/img/icon_delete@2x.png) no-repeat center;
      background-size: 100% 100%;
    }
    & /deep/ .vjs-custom-skin {
      width: 100%;
      > .video-js {
        .vjs-control-bar {
          font-size: 30px;
        }
        .vjs-big-play-button {
          width: 130px;
          height: 130px !important;
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 50%;
          border: none;
          margin-left: -65px !important;
          margin-top: -65px !important ;
          font-size: 80px !important;
          line-height: 130px !important;
        }
      }
      .video-js .vjs-volume-panel.vjs-volume-panel-horizontal {
        display: none;
      }
    }
  }
}
button,
span,
div,
button:focus,
span:focus,
div:focus,
button:before,
span:before {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-webkit-keyframes free_download {
  0% {
    -webkit-transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes free_download {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
</style>
