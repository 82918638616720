import 'lib-flexible/flexible'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCookie from 'vue-cookie'
import '@/vant'
import httpRequest from '@/utils/httpRequest' // api: https://github.com/axios/axios
import { clearNoNum } from '@/utils'
import md5 from 'js-md5'
Vue.use(VueCookie)
// let VConsole = require('../node_modules/vconsole/dist/vconsole.min')
// let vConsole = new VConsole();
// Vue.use(vConsole)
Vue.config.productionTip = false
// 挂载全局
Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.clearNoNum = clearNoNum     // 清除非数字
Vue.prototype.$md5 = md5
Vue.prototype.$cookie = VueCookie

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
