/**
 * UI组件, 统一使用vant面端组件库(https://youzan.github.io/vant/#/zh-CN）
 *
 * 使用:
 *  1. 项目中需要的组件进行释放(解开注释)
 *
 * 注意:
 *  1. 打包只会包含释放(解开注释)的组件, 减少打包文件大小
 */
import Vue from 'vue'
import {
  Form,
  Field,
  Uploader,
  Button,
  Col,
  Row,
  Grid,
  GridItem,
  Toast
} from 'vant'


Vue.use(Form)
Vue.use(Field)
Vue.use(Uploader)
Vue.use(Button)
Vue.use(Col)
Vue.use(Row)
Vue.use(Grid)
Vue.use(GridItem)



// Vue.prototype.$toast = Loading.service
Vue.prototype.$toast = Toast
