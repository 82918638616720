import Vue from 'vue'
import VueRouter from 'vue-router'
import demo from '../views/demo.vue'
import dsDemo from '../views/ds-demo.vue'
import shareTool from '../views/shareTool.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/demo',
    name: 'demo',
    component: demo
  },
  {
    path: '/daishu',
    name: 'daishu',
    component: dsDemo
  },
  {
    path: '/shareTool',
    name: 'shareTool',
    component: shareTool
  }
  // , {
  //   path: "*",
  //   component: Demo
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 路由守卫
router.beforeEach((to, from, next) => {
  // to要跳转到的路径
  // from从哪个路径来
  // next往下执行的回调
  // 在localStorage中获取token
  let token = window.sessionStorage.getItem('isShare')
  // 判断该页面是否需要登录
  if (to.meta.auth) {
    // 如果token存在直接跳转
    if (token) {
      next()
    } else {
      // 否则跳转到login登录页面
      // next({
      //     path:'/',
      //     // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
      //     query:{
      //         redirect:to.fullPath
      //     }
      // })
    }
  } else {
    // 如果不需要登录，则直接跳转到对应页面
    next()
  }
})
export default router