import { render, staticRenderFns } from "./ds-demo.vue?vue&type=template&id=baa53702&scoped=true&"
import script from "./ds-demo.vue?vue&type=script&lang=js&"
export * from "./ds-demo.vue?vue&type=script&lang=js&"
import style0 from "./ds-demo.vue?vue&type=style&index=0&id=baa53702&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baa53702",
  null
  
)

export default component.exports