<template>
  <div class="content">
    <van-form @submit="onSubmit">
      <van-field
        v-model="shareConfig.title"
        rows="2"
        autosize
        label="标题"
        type="textarea"
        maxlength="50"
        placeholder="请输入"
        show-word-limit
        :rules="dataRule.text"
      />
      <van-field
        v-model="shareConfig.desc"
        rows="2"
        autosize
        label="描述"
        type="textarea"
        maxlength="50"
        placeholder="请输入"
        show-word-limit
        :rules="dataRule.text"
      />
      <van-field
        v-model="shareConfig.link"
        name="链接"
        label="链接"
        placeholder="请输入"
        :rules="dataRule.text"
      />
      <van-field name="uploader" label="封面" :rules="dataRule.img">
        <template #input>
          <van-uploader
            v-model="uploader"
            :before-read="beforeRead"
            :after-read="afterRead"
            :max-count="1"
          />
        </template>
      </van-field>
      <van-row
        style="
          margin-top: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #eeeeee;
        "
      >
        <van-col span="5">
          <span class="preview-label">预览</span>
        </van-col>
        <van-col span="17">
          <div class="preview-area">
            <div class="preview-area-title">
              <p>
                {{ shareConfig.title }}
              </p>
            </div>
            <div class="preview-area-info">
              <p class="preview-area-info-desc">
                {{ shareConfig.desc }}
              </p>
              <div
                class="preview-area-info-img"
                :style="'background-image:url(' + shareConfig.imgUrl + ')'"
              ></div>
            </div>
            <div class="preview-area-footer">
              <div class="preview-area-footer-logo"></div>
              <span class="preview-area-footer-name">睿识课</span>
            </div>
          </div>
        </van-col>
      </van-row>
      <van-row style="margin-top: 15px">
        <van-col offset="3" span="8">
          <van-button
            round
            block
            type="primary"
            size="small"
            native-type="submit"
            >保存设置</van-button
          >
        </van-col>
        <van-col offset="2" span="8">
          <van-button round block type="default" size="small" @click="resetForm()">重置</van-button>
        </van-col>
      </van-row>
    </van-form>
  </div>
</template>

<script>
import { wxShare } from "@api/shareTool/index";
import axios from "axios";
import wx from "weixin-js-sdk";
export default {
  name: "shareTool",
  data() {
    return {
      aliyunOssToken: "",
      uploader: [],
      shareConfig: {
        imgUrl: "", // 这里是需要展示的图标
        desc: "", // 这是分享展示的摘要
        title: "", // 这是分享展示卡片的标题
        link: "http://test-demo.rskjy.cn/demo", // 这里是分享的网址
        // imgUrl: "https://rskjy.oss-cn-beijing.aliyuncs.com/default/6f39afe1e1753d75c0dab3f106acd9cb.jpeg", // 这里是需要展示的图标
        // desc: "新手倒车必看指南，不撞墙无剐蹭其实很简单", // 这是分享展示的摘要
        // title: "3分钟教你搞定安全停车", // 这是分享展示卡片的标题
        // link: "http://test-demo.rskjy.cn/demo", // 这里是分享的网址
        success: function (rr) {
          console.log("成功" + JSON.stringify(rr));
        },
        cancel: function (tt) {
          console.log("失败" + JSON.stringify(tt));
        },
      },
      dataRule: {
        text: [{ required: true, message: "不能为空" }],
        img: [{ required: true, message: "请选择图片" }]
      },
    };
  },
  mounted() {
    this.initWxShare();
  },
  components: {},
  methods: {
    beforeRead(file) {
      let imgType = file.type.split("/")[1];
      let reg = /png|jpg|jpeg$/;
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!reg.test(imgType)) {
        this.Toast("上传图片只能是 JPG、PNG 格式!");
        return false;
      }
      if (!isLt5M) {
        this.Toast("上传图片大小不能超过 2MB!");
        return false;
      }
      return true;
    },
    afterRead(file) {
      this.upload(file);
    },
    upload(file) {
      let that = this;
      let url = "http://test-oss-api.rskjy.cn/ossUpload/getSign";
      axios
        .get(url)
        .then(function (res) {
          that.aliyunOssToken = res.data;
          // key就代表文件层级和阿里云上的文件名
          let imgType = file.file.type.split("/")[1];
          let filename = file.file.name + file.file.size; // md5对图片名称进行加密
          let keyValue =
            res.data.dir + that.$md5(new Date() + filename) + "." + imgType; // 文件最终存储地址
          // 组装formdata
          let formdata = new FormData();
          formdata.append("name", file.file.name);
          formdata.append("key", keyValue);
          formdata.append("policy", that.aliyunOssToken.policy);
          formdata.append("OSSAccessKeyId", that.aliyunOssToken.accessid);
          formdata.append("success_action_status", 200);
          formdata.append("signature", that.aliyunOssToken.signature);
          formdata.append("file", file.file);
          that
            .uploadOSS(formdata, that.aliyunOssToken.host)
            // eslint-disable-next-line no-unused-vars
            .then(function (res) {
              let imgfile = that.aliyunOssToken.host + "/" + keyValue;
              that.uploader = [
                { url: imgfile, status: "done", message: "上传成功" },
              ];
              that.shareConfig.imgUrl = imgfile;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(function (error) {
              that.uploader = [
                { url: "", status: "failed", message: "上传失败" },
              ];
            });
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          that.uploader = [{ url: "", status: "failed", message: "上传失败" }];
        });
    },
    uploadOSS(formData, url) {
      const config = {
        headers: {
          "Content-Type":
            "multipart/form-data;boundary=" + new Date().getTime(),
        },
        onUploadProgress: (progressEvent) => {
          var complete =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          this.uploader[0].message = complete + "%";
        },
      };
      return axios.post(url, formData, config);
    },
    onSubmit() {
      let that = this;
      wx.ready(function () {
        wx.updateAppMessageShareData(that.shareConfig); // 微信好友 需要开通微信认证 300元/次/年
      });
      this.Toast.success('设置成功，点击右上角去分享吧');
    },
    resetForm(){
      this.shareConfig.imgUrl = ''
      this.shareConfig.desc = ''
      this.shareConfig.title = ''
      this.shareConfig.link = ''
    },
    initWxShare() {
      let params = { homeUrl: location.href.split("#")[0] }; //一定是当前页面的url，确保是当前页面调用的分享功能
      wxShare(params).then(({ data }) => {
        if (data && data.success === true) {
          wx.config({
            debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.data.appId, // 必填，公众号的唯一标识
            timestamp: data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
            signature: data.data.signature.toLowerCase(), // 必填，签名
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
          });
        } else {
          console.log(data.message);
        }
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  height: 1218px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  position: relative;
  // color: red;
  .preview-label {
    padding-left: 32px;
    color: #646566;
    text-align: left;
    font-size: 30px;
  }
  .preview-area {
    width: 482px;
    height: 240px;
    padding: 26px;
    box-shadow: 0px 4px 20px 0px rgba(216, 216, 216, 0.5);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    &-title {
      width: 100%;
      p {
        margin: 0;
        font-size: 32px;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
        overflow: hidden;
        word-wrap: break-word;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
      }
    }
    &-info {
      display: flex;
      margin-top: 14px;
      &-desc {
        width: 338px;
        font-size: 26px;
        line-height: 36px;
        font-weight: 400;
        color: #666666;
        overflow: hidden;
        margin: 0;
        word-wrap: break-word;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
      }
      &-img {
        width: 130px;
        height: 130px;
        background-size: 130px 130px;
      }
    }
    &-footer {
      margin-top: 20px;
      padding-top: 13px;
      border-top: 1px solid #eeeeee; /* no*/
      display: flex;
      &-logo {
        width: 32px;
        height: 32px;
        background: url(../assets/img/logo.jpg) no-repeat center;
        background-size: 32px 32px;
        margin-right: 14px;
      }
      &-name {
        font-size: 24px;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
      }
    }
  }
}
</style>
