// 实现深度拷贝
export const deepClone = (obj) => {
  var newObj
  // 数组类型的拷贝
  if (obj instanceof Array) {
    newObj = []
    var i = obj.length
    while (i--) {
      newObj[i] = deepClone(obj[i])
    }
    return newObj
  } else if (obj instanceof Object) {
    newObj = {}
    for (var k in obj) {
      newObj[k] = deepClone(obj[k])
    }
    return newObj
  } else {
    return obj
  }
}
// 去除null
export function filterNull (_data) {
  let results = deepClone(_data)
  function trimSpace (array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i] == '' || array[i] == ' ' || array[i] == null || typeof (array[i]) == 'undefined') {
          array.splice(i, 1)
          i = i - 1
        }
      }
    return array
  }
  trimSpace(results)
  return results
}

// 清除非数字
export function clearNoNum (obj, decimal) {
  if (decimal) {
    obj = obj.replace(/[^\d]/g, '')
  } else {
    // 先把非数字的都替换掉，除了数字和.
    obj = obj.replace(/[^\d.]/g, '')
  // 必须保证第一个为数字而不是.
    obj = obj.replace(/^\./g, '')
  // 保证只有出现一个.而没有多个.
    obj = obj.replace(/\.{2,}/g, '.')
  // 保证.只出现一次，而不能出现两次以上
    obj = obj.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
    // 只能输入两个小数
    obj = obj.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
  }
  return obj
}